import { environmentURLs } from "../utils/environmentURLs";
const { frontendAPIUrl } = environmentURLs;

const handleUserLogin = async (email, password) => {
  const requestBody = {
    email: email,
    password: password,
  };
  try {
    const response = await fetch(`${frontendAPIUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const result = await response.json();
    if(result.error){
      throw new Error(result.authFlag || "internalServerError");
    }
    return { data: result.data };
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};

const handleUserRegistration = async (name, email, password, redirectTo) => {
  try {
    const response = await fetch(`${frontendAPIUrl}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name, email: email, password: password,redirectTo }),
    });


    const result = await response.json();
    if(result.error){
      throw new Error(result.authFlag || "internalServerError");
    }

    return { data: result.data };
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};

const generateMagicLink = async (params) => {
  const {
    email,
    name,
    source,
    redirectTo,
  } = params;

  try {
    const response = await fetch(
      `${frontendAPIUrl}/login/generate-magic-link`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          name: name,
          redirectTo: redirectTo,
          source: source,
        }),
      }
    );


    const result = await response.json();
    console.log("result is: ", result.error);
    if(result.error){
      if(result.error === "Name is required for new user registration"){
        throw new Error("Name is required for new user registration");
      }
      throw new Error(result.authFlag || "internalServerError");
    }

    return true;
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};


async function verifyToken(token, id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
try {
  const response = await fetch(`${frontendAPIUrl}/user/${id}/`, requestOptions)
  
  const result = await response.json();
  if(result.error){
    throw new Error(result.authFlag || "internalServerError");
  }
  const user = result.data;
  return user;
}
catch (thrownError) {
  console.error("An error occurred:", thrownError);
  throw thrownError;
}
}



const verifyMagicLink = async (token) => {
  if (!token) {
    throw new Error("Invalid token. Please try again.");
  }
  try {
    const response = await fetch(`${frontendAPIUrl}/login/verify-magic-link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    });


    const result = await response.json();
    if(result.error){
      throw new Error(result.authFlag || "internalServerError");
    }

    return { data: result.data };
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};

const validateAndProcessToken = async (token) => {
  try {
    const response = await fetch(`${frontendAPIUrl}/login/auth/google`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...token }),
    });


    const result = await response.json();
    if(result.error){
      throw new Error(result.authFlag || "internalServerError");
    }

    return { data: result.data };
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};


export {
  handleUserLogin,
  handleUserRegistration,
  generateMagicLink,
  verifyMagicLink,
  validateAndProcessToken,
  verifyToken
};
