import { useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { configExtras } from "./utils/config";
import { UserProvider } from "./contexts/userContext";


// Components imports
import { AuthenticationContainer } from "./pages/AuthenticationContainer/AuthenticationContainer";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { getCookie } from "./helpers/cookieHelpers";
import { verifyToken } from "./services/authService";
import { extractRedirectFromURL } from "./helpers/extractRedirectFromURL";
import { generateUrl } from "./helpers/generateURL";



const GOOGLE_CLIENT_ID = configExtras.GOOGLE_CLIENT_ID;

function App() {

  useEffect(() => {
    if (window.location.pathname === '/logout') {
      return; // Exit the effect early if on /logout
    }
    //if token exists, we redirect the user to dev.codedesign.ai

    async function verifyTokenCall() {
      console.log("verifyingToken")
      const token =  getCookie('token');
      const uRI = getCookie("userId");
  
      if(token && uRI){
        const user = await verifyToken(token, uRI)
        if(user) {
       
          const redirect = extractRedirectFromURL(window.location.search);
          generateUrl(user,redirect);
        }
      }
    }
  verifyTokenCall();
  }, [])

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <UserProvider>
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/*" element={<AuthenticationContainer />} />
        </Routes>
        <Footer />
      </div>
    </Router>
    </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;

