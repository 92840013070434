import analytics from "codedesign-session-analytics";
import { environmentURLs } from "../utils/environmentURLs";
import { getCookie, setCookie } from "./cookieHelpers";
const { frontendUrl: url } = environmentURLs;

function setCookiesForEnvironment(env, token, _id) {
  let domain;
  if (env?.startsWith("dev")) {
    domain = ".codedesign.app";
    setCookie("token", token, domain);
    setCookie("userId", _id, domain);
    domain = "staging-accounts.codedesign.app";
    setCookie("token", token, domain);
    setCookie("userId", _id, domain);
  } else if (env?.startsWith("prod")) {
    domain = ".codedesign.ai";
    setCookie("token", token, domain);
    setCookie("userId", _id, domain);
    domain = "accounts.codedesign.ai";
    setCookie("token", token, domain);
    setCookie("userId", _id, domain);
  }
}

export const generateUrl = async (user, redirect = "/a/dashboard") => {
  const { _id, token } = user;
  const environment = process.env.REACT_APP_ENV || "development";

  setCookiesForEnvironment(environment, token, _id);

  await checkSessionAndUserId(_id);
  const uRI = _id;

  let authURL;
  if (redirect) {
    authURL = `${url}/${uRI}?action=login&uRI=${uRI}&redirect=${redirect}`;
  } else {
    //the default case, redirect to dev.codedesign.ai/
    authURL = `${url}/${uRI}?action=login&uRI=${uRI}&redirect=${encodeURIComponent(
      "/"
    )}`;
  }
  window.location = authURL;
};

async function checkSessionAndUserId(_id) {
  try {
    // Retrieve userId from analytics state or cookie
    const analyticsState = analytics.getState(); // Handle the analytics state
    const userIdFromAnalytics = analyticsState
      ? analyticsState.user.userId
      : null;
    const userIdFromCookie = getCookie("userId");
    let userId = userIdFromAnalytics || userIdFromCookie || _id;

    // Check for userId in localStorage if not found in analytics state or cookie
    if (!userId) {
      userId = localStorage.getItem("uRI");
    }

    // Identify the user in analytics if userId is available
    if (userId) {
      await analytics.identify(userId); // Ensure identify is handled asynchronously

      // Track events after successful identification
      analytics.track("User identified successfully");
    }
  } catch (err) {
    console.error("An error occurred within the analytics module", err);
  }
}
